<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Remove Tags</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form @submit="handleRemoveTags" v-slot="{ errors }">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">
                                    Select Tags
                                </label>
                                <div class="field_wpr" :class="{ 'has-error': errors.tag }">
                                    <Field autocomplete="off" name="tag" v-model="form.tags_id" rules="required">
                                        <multiselect
                                            v-model="form.tags_id"
                                            mode="tags"
                                            label="name"
                                            value-prop="id"
                                            :searchable="true"
                                            :group-select="true"
                                            :options="tagsGroup"
                                            :groups="true"
                                            group-label="type"
                                            group-options="tags"
                                            :group-hide-empty="true"
                                            placeholder="Select Tags"
                                        >
                                            <template v-slot:grouplabel="{ group }">
                                                <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                            </template>
                                            <template v-slot:option="{ option }">
                                                <span>{{ option.name }}</span>
                                            </template>
                                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                  {{ option.name }}
                                                  <span
                                                    v-if="!disabled"
                                                    class="multiselect-tag-remove"
                                                    @mousedown.prevent="handleTagRemove(option, $event)"
                                                  >
                                                      <span class="multiselect-tag-remove-icon"></span>
                                                  </span>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="tag" class="text-danger" />
                            </div>
                        </div>
                        <div v-show="form.tags_id.length">
                            <schedule-component :schedule-data="form" title="tag" :close-modal="closeModal" />
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="bulkActionLoader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i>{{ bulkActionLoader ? ' Removing' : 'Remove' }}</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ScheduleComponent = defineAsyncComponent(() => import('@/components/Schedule'))

    export default {
        name: 'Remove Tag',

        data () {
            return {
                createTags: false,
                form: {
                    tags_id: [],
                    contacts: [],
                    action: '/contacts/remove-tag',
                    type: 'leads'
                },
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
        },

        emits: ['update:modelValue'],

        watch: {
            contacts (contacts) {
                const vm = this;

                vm.form.contacts = contacts;
            },

            modelValue (value) {
                const vm = this;
                if (value == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                if (vm.tagsGroup.length == 0) {
                    vm.getTags();
                }
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            ScheduleComponent,
        },

        computed: {
            ...mapState({
                tagsGroup: state => state.tagModule.tagsGroup,
                bulkActionLoader: state => state.contactModule.bulkActionLoader,
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getTags: 'tagModule/getTags',
                removeTag: 'tagModule/removeTag',
            }),

            closeModal () {
                const vm = this;

                vm.resetForm();
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    tags_id: [],
                    contacts: vm.contacts,
                    action: '/contacts/remove-tag',
                    type: 'leads'
                };
            },

            handleRemoveTags (params, { setFieldError }) {
                const vm = this;
                vm.form.setFieldError = setFieldError;
                vm.form.closeModal = vm.closeModal;

                vm.removeTag(vm.form);
            },
        },
    }
</script>
